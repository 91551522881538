import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import SocialList from '../Common/SocialList';

const Address = ({
  keyToPass,
  address_1,
  address_2,
  city,
  country,
  postcode,
  telephone,
  email,
  fax,
}) => {
  const cssWrapper = css`
    ul {
      padding: 0;
    }
  `;

  const cssRow = css`
    width: 100%;
  `;

  const cssList = css`
    list-style: none;
    margin-left: 0 !important;
    color: #fff;
  `;

  return (
    <div className={cssWrapper}>
      <Container fluid style={{ padding: '0', margin: '0' }}>
        <Row className={cssRow}>
          <Col xs={12} md={6}>
            <ul key={keyToPass} className={cssList}>
              {address_1 && <li>{address_1}</li>}
              {address_2 && <li>{address_2}</li>}
              {city && <li>{city}</li>}
              {country && <li>{country}</li>}
              {postcode && <li>{postcode}</li>}
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <ul className={cssList}>
              {email && <li>E: {email}</li>}
              {fax && <li>F: {fax}</li>}
              {telephone && <li>T: {telephone}</li>}
            </ul>

            <SocialList color="#FFF" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Address.defaultProps = {
  keyToPass: null,
  address_1: null,
  address_2: null,
  city: null,
  country: null,
  postcode: null,
  telephone: null,
  fax: null,
  email: null,
};

Address.propTypes = {
  keyToPass: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  postcode: PropTypes.string,
  telephone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
};

export default Address;

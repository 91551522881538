import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import { css } from 'emotion';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

import Title from '../Common/Title';

const ToggleContent = ({ qi, question, answer }) => {
  const [isToggled, setIsToggled] = useState(false);

  const cssQuestion = css`
    transition: 0.3s ease-in-out;
    display: block;
    width: 100%;
    text-align: left;

    background-color: transparent;
    border: none;
    margin-bottom: ${(isToggled && '1.5rem') || '3rem'};
    outline: none;
    cursor: pointer;
    padding: 0;

    &:first-of-type {
      margin-top: 0;
    }

    h5 {
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      font-weight: 500;

      color: ${(isToggled && themeVars.root.primary) || rgba(themeVars.root.dark, 0.5)};

      &:hover {
        color: ${(isToggled && themeVars.root.primary) || rgba(themeVars.root.dark, 1)};
      }
    }
  `;

  const cssAnswer = css`
    font-family: ${themeVars.root.headerFontFamily};
    -webkit-font-smoothing: antialiased;

    margin-bottom: ${isToggled ? '6rem' : '0'};
  `;

  function handleToggle() {
    setIsToggled(isToggled === false);
  }

  return (
    <div>
      <button className={cssQuestion} onClick={handleToggle}>
        <Title size={5}>{`${qi + 1}. ${question}`}</Title>
      </button>

      <Collapse in={isToggled} collapsedHeight="0">
        <p className={cssAnswer}>{answer}</p>
      </Collapse>
    </div>
  );
};

ToggleContent.propTypes = {
  qi: PropTypes.number.isRequired,
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default ToggleContent;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { graphql, StaticQuery } from 'gatsby';
import shortid from 'shortid';

import LayoutPage from '../components/Layouts/LayoutPage';
import Section from '../components/Contact/Section';
import ToggleContent from '../components/Contact/ToggleContent';
import { themeVars } from '../config/styles';

import Address from '../components/Contact/Address';
import ContactForm from '../components/Contact/ContactForm';

const ContactPage = () => {
  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }

          wordpressPage(slug: { eq: "contact" }, status: { eq: "publish" }) {
            yoast {
              title
              metadesc
              linkdex
              metakeywords
              meta_robots_noindex
              meta_robots_nofollow
              meta_robots_adv
              canonical
              redirect
              opengraph_title
              opengraph_description
              opengraph_image
              twitter_title
              twitter_description
            }

            link
            title

            acf {
              page_hero_title
              page_hero_subtitle
              page_hero_bg {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 75, toFormat: JPG) {
                      tracedSVG
                      sizes
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
              contact_faq {
                question
                answer
              }
            }
          }

          wordpressAcfOptions {
            options {
              options_rescue {
                panel_one_title
                panel_one_label
                panel_one_page {
                  post_name
                }
                panel_two_title
                panel_two_label
                panel_two_page {
                  post_name
                }
              }

              options_address {
                address_1
                address_2
                city
                postcode
                country
              }

              options_contact {
                telephone
                fax
                email
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          address_1,
          address_2,
          city,
          country,
          postcode,
        } = data.wordpressAcfOptions.options.options_address;

        const { email, fax, telephone } = data.wordpressAcfOptions.options.options_contact;

        return (
          <LayoutPage
            data={data.wordpressPage}
            optionsData={data.wordpressAcfOptions}
            siteMeta={data.site}
          >
            <section>
              <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row nogutter>
                  <Col md={12} xl={6} lg={12} xs={12}>
                    <Section title="FAQs">
                      {data.wordpressPage.acf.contact_faq.map((item, index) => (
                        <ToggleContent
                          key={shortid.generate()}
                          qi={index}
                          question={item.question}
                          answer={item.answer}
                        />
                      ))}
                    </Section>
                  </Col>
                  <Col xs={12} lg={12} xl={6} style={{ backgroundColor: themeVars.root.primary }}>
                    <div>
                      <Section title="Contact and Support" color={themeVars.root.light}>
                        <div>
                          <Address
                            keyToPass={shortid.generate()}
                            address_1={address_1}
                            address_2={address_2}
                            city={city}
                            country={country}
                            postcode={postcode}
                            email={email}
                            fax={fax}
                            telephone={telephone}
                          />
                        </div>
                        <ContactForm />
                      </Section>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </LayoutPage>
        );
      }}
    />
  );
};

export default ContactPage;

import React from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import Title from '../Common/Title';
import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

const Section = ({ children, title, color }) => {
  const cssWrapper = css`
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;

  return (
    <div className={cssWrapper}>
      <Row>
        <Col xs={10} push={{ xs: 1 }}>
          <Row>
            <Col xs={12} md={10} push={{ md: 1 }}>
              <Title size={2} color={color}>
                {title}
              </Title>

              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Section.defaultProps = {
  color: `${themeVars.root.dark}`,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Section;
